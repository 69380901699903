@import '../../includes/all';

$browser-upgrade-bg: #f2dede;
$browser-upgrade-color: #a94441;
$browser-upgrade-border: #ebccd1;

button:not(.custom-btn):not([data-toggle='dropdown']):not(.btn-primary) {
  border: 0;

  &:not(.small-white):not(.small-blue):not(.simple) {
    height: 30px;
    width: 80px;
    font-size: 16px;
    background-color: $btn-bg;
    border-bottom: 2px solid $btn-border-color;
    color: $btn-color;
    padding: 1px 6px;

    @media screen and (min-device-width: $medium) {
      &:hover {
        border-bottom-width: 1px;
        padding-top: 2px;
        vertical-align: top;

        > img {
          top: 7px !important;
        }
      }
    }
  }
}

body {
  height: 100%;
  font-size: 14px;
}

* {
  @include font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-light;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 40px;

  &,
  & span {
    @include font-light;
  }
}

h3 {
  font-size: 20px;
  margin: 15px 0 5px;
}

h4 {
  margin: 0;
  font-size: 20px;
}

a {
  text-decoration: none;

  &[href] {
    color: $link-color;
  }
}

input,
select,
button {
  outline-color: $outline-color;
}

select {
  font-size: 12px;
  padding: 0 0 0 5px;
}

input {
  &[type='text'],
  &[type='password'],
  &[type='email'],
  &[type='tel'] {
    padding: 0 0 0 10px;
  }
}

select,
input:not([type='checkbox']) {
  height: 33px;

  &[disabled] {
    background-color: $input-disabled-bg !important;
    color: $input-disabled-color;
  }
}

.freshwidget-button {
  display: none !important;

  > a {
    border-radius: 0 !important;
    color: $btn-color !important;
    background-color: $btn-bg !important;
    font-weight: normal !important;
  }
}

#lc_chat_layout {
  &,
  & span,
  & p {
    @include font-regular;
  }

  #lc_chat_header {
    background-color: $btn-bg !important;
    border-color: $btn-border-color !important;
    color: $btn-color !important;
    border-radius: 0 !important;

    #lc_chat_title {
      font-size: 16px !important;
    }
  }
}

#browserupgrade {
  margin: 0;
  background: $browser-upgrade-bg;
  color: $browser-upgrade-color;
  border: 1px solid $browser-upgrade-border;
  padding: 5px;
  text-align: center;
  font-size: 40px;
}

// Placeholders
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $input-placeholder-color;
  opacity: 1;
}
