@import '../../includes/all';
@import '../../vendor/normalize.min.css';

$android-bg: white;
$selection-bg: #b3d4fc;
$hr-color: #9b9b9b;
$input-border: $input-border-color;
$input-bg: white;

* {
  box-sizing: border-box;
}

::-moz-selection,
::selection {
  background: $selection-bg;
  text-shadow: none;
}

html {
  color: $text-color-dark;
  font-size: 1em;
  line-height: 1.4;
  height: 100%;

  @include desktop {
    min-height: 100%;

    &.framed {
      height: 100%;
    }
  }

  @include mobile {
    &.android:not(.m) {
      background: $android-bg;
    }

    &:not(.android),
    &.m {
      height: 100%;
    }
  }

  &.safari {
    select:not(.custom):not([disabled]) {
      // Remove gradient from select on iOS
      background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+') no-repeat 95% 50%;
      appearance: none;
    }
  }
}

body {
  margin: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $hr-color;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

input,
select,
textarea {
  color: $text-color-dark;
  border: 1px solid $input-border;
  border-radius: 0;
  background-color: $input-bg;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}
