$print-color: black;
$print-border: #999;

@media print {
  * {
    background: transparent !important;
    color: $print-color !important;
    box-shadow: none !important;
    text-shadow: none !important;

    &::before,
    &::after {
      background: transparent !important;
      color: $print-color !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }
  }

  a {
    &,
    &:visited {
      text-decoration: underline;
    }

    &[href]::after {
      content: ' (' attr(href) ')';
    }

    &[href^='#']::after,
    &[href^='javascript:']::after {
      content: '';
    }
  }

  abb
  r[title]::after {
    content: ' (' attr(title) ')';
  }

  pre,
  blockquote {
    border: 1px solid $print-border;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr {
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
