@import '../../includes/all';

$red-text: #d0021b;

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

.invisible {
  visibility: hidden;
}

.clearfix {
  @include clearfix;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.site-component {
  margin: 0 auto;
  padding: 32px 15px;
  max-width: 990px;

  @include mobile {
    padding: 20px 15px;
  }

  @include phone {
    padding-top: 10px;

    &:not(:last-of-type) {
      padding-bottom: 10px;
    }
  }
}

.inset-shadow {
  box-shadow: inset 0 0 10px 3px rgba(0, 0, 0, .3);
}

.inset-shadow2 {
  box-shadow: inset 0 0 10px 3px rgba(0, 0, 0, .1);
}

.red-text {
  color: $red-text !important;
}
