#header-settings {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  #currency-switcher {
    display: none;
  }

  @media screen and (max-width: 930px) {
    position: absolute;
    top: 30px;
    right: 20px;

    #currency-switcher {
      display: none;
    }
  }
}

#currency-switcher {
  text-align: center;

  > span {
    padding: 2px;
    font-size: 20px;
  }
}

#language-switcher {
  > span,
  > a {
    overflow: hidden;
    width: 100%;
    padding: 5px;

    .flag {
      width: 31px;
    }
  }
}

#language-switcher,
#currency-switcher {
  margin-left: 5px;
  display: inline-block;
  height: 33px;
  vertical-align: middle;
  z-index: 100;
  position: relative;

  > span:hover {
    cursor: default;
  }

  > a,
  > span {
    display: block;
    position: relative;
    border: 1px solid $input-border-color;
    background: white no-repeat center;
    background-size: 30px;

    &:not(:first-child):hover {
      background-color: $selection-hover-color;
      cursor: pointer;
    }

    &:not(:first-child) {
      display: none;
    }

    > span {
      color: $text-color-dark;
      display: block;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover,
  &:focus {
    outline: none;

    > a,
    > span {
      display: block;

      &:not(:last-child) {
        border-bottom: 0;
      }

      &:not(:first-child) {
        border-top: 0;
      }
    }
  }
}
