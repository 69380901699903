@import '../../../includes/all';

$nav-hover-color: #035ab4;

html.framed #wl-header {
  display: block !important;
}

header {
  // TODO: Temp - Overriding widget styling
  height: auto !important;
  display: block !important;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, .1);
  position: relative;
  z-index: 1;

  > nav {
    padding: 10px 15px !important;
    position: relative;

    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

#header-links {
  text-transform: lowercase;

  @media screen and (min-width: $large) {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: $large) {
    margin-left: 50px;
  }

  @media screen and (max-width: 950px) {
    margin-left: 35px;
  }

  > a,
  > div {
    @include font-light;

    color: $text-color-dark;
    font-size: 20px;
    display: inline-block;
    margin-right: 1em;
  }
}

@media screen and (max-width: 515px) {
  #header-links {
    display: block;
    text-align: center;
    margin-top: 5px;
    margin-left: 0;

    > a,
    > div {
      font-size: 21px;
    }
  }

  #logo-container {
    display: block !important;
    margin: auto;
    text-align: center;
  }
}

#logo-container {
  max-width: 100%;
  display: inline-block;
}

#header-logo,
#partnership-with {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  display: inline-block;
  vertical-align: middle;
  height: 100%;
  max-width: 100%;
}

#header-logo {
  background-image: url($logo);
  width: 210px;
}

#partnership-with {
  background-image: url($partnership-img);
  width: 200px;

  @media screen and (max-width: 540px) {
    display: none;
  }
}
