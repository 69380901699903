@import '../../includes/all';
@import 'layout/footer';
@import 'layout/header-settings';
@import 'layout/header';
@import 'layout/subnav';

$header-height: 90px;
$footer-height: 313px;
$logo-width: 140px;
$extra-height: 0;
$dark-text: #777;
$header-bg-color: white;

header {
  min-height: $header-height !important;
  background-color: $header-bg-color;

  > nav {
    height: $header-height;

    @media screen and (max-width: 930px) {
      height: 100%;
    }

    .menu-part {
      width: calc((90% - #{$logo-width}) / 2);
    }
  }
}

#logo-container {
  height: $header-height - 20px;
}

.page-image {
  background: no-repeat center;
  background-size: cover;
}

main {
  clear: both;
  min-height: calc(100% - #{$header-height + $footer-height + $extra-height});

  > #content {
    height: 100%;
  }

  h1 {
    text-shadow: 0 0 3px rgba(0, 0, 0, .5);
  }
}

footer {
  min-height: $footer-height;
}

h1,
h2 {
  overflow: hidden;
  text-overflow: ellipsis;
}

h2 {
  clear: both;
}
