@import '../../includes/media';

@media only screen and (max-device-width: $medium) {
  .desktop {
    display: none !important;
  }
}

@media only screen and (min-device-width: $medium) {
  .mobile,
  .mobiel {
    display: none !important;
  }
}
