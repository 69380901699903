@import '../../../includes/all';

$subnav-bg: white;

.nav-item {
  position: relative;

  a {
    @include font-light;

    color: $text-color-dark !important;
    padding-bottom: 20px;
    white-space: nowrap; // For height
  }
}

.nav-item,
.subnav-item {
  &:hover {
    .subnav-item {
      display: block;
    }
  }
}

.subnav-item {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: $subnav-bg;
  font-size: 18px;
  top: 20px;
}

ul.subnav-item {
  list-style: none;
  margin: 10px 0 0;
  padding: 10px 0;
  left: -15px;

  li {
    &:not(:last-child),
    &:last-child a {
      display: block;
      padding: 0 15px;
    }
  }

  hr {
    margin: 5px 0;
  }
}
