@import '../../../includes/all';

$footer-bg: #282828;
$footer-color: #969696;
$footer-heading-color: $text-color-light;
$footer-bottom-color: $text-color-light;

footer {
  background-color: $footer-bg;

  > nav > div {
    width: 25%;
    display: inline-block;
    vertical-align: top;

    @include phone {
      width: auto;
      margin-right: 5px;
    }

    > a,
    > div {
      color: $footer-color !important;
      text-decoration: none;
      display: inline-block;
      margin-bottom: 3px;
    }

    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      color: $footer-color;

      > li {
        margin-bottom: 3px;
      }
    }
  }

  .footer-icon {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-top: 5px;
  }

  h3 {
    text-transform: uppercase;
    color: $footer-heading-color;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:last-child {
    color: $footer-bottom-color;
  }

  abbr {
    text-decoration: none !important;
  }
}

#footer-copyright {
  padding: 10px;
  text-align: center;
}

#payment-method-icons {
  width: 100%;
}
