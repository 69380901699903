$theme-bg: #3c7dfa;
$logo: 'https://cdn.themes.get-e.com/geteasytransfers.get-e.com/images/3ophKF4Zla5oYfQgnqB8RONQy8Uubj6n7O3Yds4m.png';
$benefits-checkmark-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='19' viewBox='0 0 24 19'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='4' d='M3 11.125L7.875 16l13-13' stroke-linecap='square'/%3E%3C/svg%3E";
$review-star-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1235' height='1175'%3E%3Cpath fill='%233c7dfa' d='M0 449h1235l-999 726L618 0l382 1175z'/%3E%3C/svg%3E";
// Base colors
$theme-bg: #3c7dfa !default;
$theme-text: $theme-bg !default;
$theme-heading: #094180 !default;

// Text colors
$link-color: #035ab4 !default;
$text-color-dark: #222 !default;
$text-color-light: white !default;

// Button colors
$btn-color: $text-color-light !default;
$btn-bg: $theme-bg !default;
$btn-border-color: darken($btn-bg, 20%) !default;

// Input colors
$outline-color: $theme-text !default;
$input-border-color: #dcdcdc !default;
$input-placeholder-color: #4a4a4a !default;

$selection-hover-color: #f4f4f4 !default;

$input-disabled-color: #9b9b9b !default;
$input-disabled-bg: #f4f4f4 !default;

// Other colors
$hr-color: #979797 !default;

// SVG fallbacks
$benefits-checkmark-svg: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19"%3E%3Cpath fill="none" stroke="%23fff" stroke-width="4" d="M3 11.125L7.875 16l13-13" stroke-linecap="square"/%3E%3C/svg%3E' !default;
$review-star-svg: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1235" height="1175"%3E%3Cpath fill="%233c7dfa" d="M0 449h1235l-999 726L618 0l382 1175z"/%3E%3C/svg%3E' !default;

// Images
$logo: $asset-url + '/images/layout/logo.png' !default;
$partnership-img: $asset-url + '/images/layout/in-partnership-with.png' !default;

// Home page
$home-page-bg: $asset-url + '/images/home/background.jpg' !default;
$benefits-checkmark-img: $benefits-checkmark-svg !default;

$destination1-img: $asset-url + '/images/home/cities/barcelona.jpg' !default;
$destination2-img: $asset-url + '/images/home/cities/berlin.jpg' !default;
$destination3-img: $asset-url + '/images/home/cities/madrid.jpg' !default;
$all-destinations-img: $asset-url + '/images/home/all-destinations.jpg' !default;

$stories1-img: $asset-url + '/images/home/story-trip/story1.jpg' !default;
$stories2-img: $asset-url + '/images/home/story-trip/story2.jpg' !default;
$stories3-img: $asset-url + '/images/home/story-trip/story3.jpg' !default;

$review-star: $review-star-svg !default;
$reviewer1-img: $asset-url + '/images/home/reviews/reviewer1.jpg' !default;
$reviewer2-img: $asset-url + '/images/home/reviews/reviewer2.jpg' !default;
$reviewer3-img: $asset-url + '/images/home/reviews/reviewer3.jpg' !default;

// Destinations page
$destinations-page-bg: $asset-url + '/images/destinations/background.jpg' !default;

// FAQ page
$faq-page-bg: $asset-url + '/images/faq/background.jpg' !default;

// Contact page
$contact-page-bg: $asset-url + '/images/contact/background.jpg' !default;
